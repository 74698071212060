.open g rect {
  fill: #d3d3d3 !important;
}

.delayed g rect {
  fill: #ff7800 !important;
}

.in-progress g rect {
  fill: #4194f6 !important;
}

.refactor g rect {
  fill: #f4b400 !important;
}

.unit-test g rect {
  fill: #ef0000 !important;
}

.review g rect {
  fill: #a875ff !important;
}
